<template>
    <div class="partner" :class="{active: isActive}">
        <a
            @click="clickEvents($event, data.id)"
            class="partner__link"
            :href="data.link"
            target="_blank"
        ></a>
        <div class="partner__item-content">
            <div class="partner__item-top">
                <div class="partner__logo img-wrapper d-flex align-items-center justify-content-center">
                    <img :src="data.logo" :alt="data.title"/>
                </div>
                <div class="partner__info-block">
                    <div class="partner__sum-block">
                        <p class="partner__sum">
                            {{ data.header }} <span>₽</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="partner__percent">
                0% /день
            </div>
            <div class="partner__desc">
                {{ data.subHeader }}
            </div>
            <base-button
                class="partner__action"
                type="button"
                mode="yellow"
            >
                {{ buttonCaption }}
            </base-button>
        </div>
    </div>
</template>

<script>
import './partner.scss'
import finalStat from '@/api/finalStat';

export default {
    name: 'Partner',
    data() {
        return {
            isActive: false,
        }
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        initSteps: {
            type: Number
        },
        finalSteps: {
            type: Number
        },
        offerPos: {
            type: Number
        }
    },
    emits: [
        'increase',
        'decrease',
        'jumpToEnd',
        'checkPopup',
        'filterActive',
        'clickActive',
    ],
    computed: {
        buttonCaption() {
            return this.isActive ? 'Отменить' : 'Получить';
        }
    },
    methods: {
        clickEvents(e, id) {
            this.sendInfo(id);
            this.toggleActive(e);
        },
        sendInfo(id) {
            try {
                finalStat({
                    id
                })
            } catch(e) {
                console.log('cant sent stat');
            }
        },
        toggleActive(e) {
            if (!this.isActive) {
                this.isActive = true;
                this.getToEnd();
                this.showPopup();
                this.filterActive();
                this.increaseNum();
            } else {
                e.preventDefault();
                this.isActive = false;
                this.decreaseNum();
                this.clickActiveOffer();
            }
        },
        increaseNum() {
            this.$emit('increase');
        },
        decreaseNum() {
            this.$emit('decrease');
        },
        getToEnd() {
            this.$emit('jumpToEnd', this.offerPos);
        },
        showPopup() {
            this.$emit('checkPopup', true);
        },
        filterActive() {
            this.$emit('filterActive', this.data.id);
        },
        clickActiveOffer() {
            this.$emit('clickActive', this.data.id);
        },
    },
}
</script>
